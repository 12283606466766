<template>
  <v-card>
    <v-card-title>
      <v-icon>mdi-account</v-icon>
      Nutzer Suchen
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-alert v-if="no_result" type="warning">nicht gefunden</v-alert>
        <SearchUserInput v-if="isAuthenticated === true"></SearchUserInput>

        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <div v-if="result">
          <v-divider></v-divider>
          <v-subheader>Suchergebnis:</v-subheader>
          <v-list>
            <v-list-item-group>
              <v-list-item
                :to="{ name: 'user-detail', params: { id: result.id } }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ result.data.username }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ result.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>

        <v-overlay :value="search_in_progress">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import SearchUserInput from "./SearchUserInput.vue";
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
export default {
  data() {
    return {
      query: "",
      sentquery: "",
      isLoading: false,
      error: null,
      autocomplete_model: {},
    };
  },
  model: {
    prop: "query",
    event: "input",
  },
  computed: {
    ...mapGetters({
      result: "search/result",
      previous_results: "search/previousResults",
      search_in_progress: "search/searchInProgress",
      no_result: "search/noResult",
      autocompleteResults: "search/previousResultsByQuery",
      isAuthenticated: "auth/isAuthenticated",
    }),
    autocomplete() {
      return this.autocompleteResults(this.query);
    },
  },
  methods: {
    search() {
      this.sentquery = this.query;
      this.$store.dispatch("search/search", { query: this.query });
    },
    checkSearchEnter(key) {
      console.log("key:", key);
    },
  },
  components: { SearchUserInput },
};
</script>
