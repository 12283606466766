<template>
  <v-container>
    <!-- <v-text-field v-model="query"></v-text-field> -->
    <v-overlay :value="search_in_progress && 1 == 2">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-autocomplete
      v-model="autocomplete_model"
      :items="mergedResults"
      :search-input.sync="query"
      color="white"
      hide-no-data
      :solo="false"
      :loading="search_in_progress"
      item-text="query"
      item-value="query"
      placeholder="Suchen"
      prepend-icon="mdi-database-search"
      return-object
    >
      <template v-slot:item="data">
        <v-list-item
          :to="{ name: 'user-detail', params: { id: data.item.id } }"
        >
          <v-list-item-content>
            <v-list-item-title>{{ data.item.data.username }}</v-list-item-title>
            <v-list-item-subtitle>{{ data.item.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
export default {
  data() {
    return {
      query: "",
      isLoading: false,
      searchActive: true,
      error: null,
      autocomplete_model: {},
      timer: null
    };
  },

  computed: {
    ...mapGetters({
      result: "search/result",
      previous_results: "search/previousResults",
      search_in_progress: "search/searchInProgress",
      no_result: "search/noResult",
      autocompleteResults: "search/previousResultsByQuery"
    }),
    mergedResults() {
      const res = res
        ? this.result.map(res =>
            Object.assign({}, res, { type: "result", query: this.query })
          )
        : [];
      const prev_res = this.previous_results.map(res =>
        Object.assign({}, res, { type: "prev_result" })
      );
      return [...res, ...prev_res];
    },
    autocomplete() {
      return this.autocompleteResults(this.query);
    }
  },
  watch: {
    query(after, before) {
      console.log(after, before);
      if (
        after &&
        (!before ||
          (after.length > 0 &&
            after !== before &&
            after.length >= before.length))
      ) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        this.timer = setTimeout(() => {
          // your code
          this.search();
        }, 800);
      }
    }
  },
  methods: {
    search() {
      this.$store.dispatch("search/search", { query: this.query });
    }
  }
};
</script>
