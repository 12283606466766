<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col cols="6">
          <SearchUserForm></SearchUserForm>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title
              ><v-icon>mdi-book</v-icon> Wörter verwalten</v-card-title
            >
            <v-card-text>
              <v-list>
                <v-list-item to="/words/">
                  <v-list-item-icon
                    ><v-icon>mdi-tooltip-image</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>Wörter verwalten</v-list-item-title>
                </v-list-item>
                <v-list-item to="/categories/">
                  <v-list-item-icon
                    ><v-icon>mdi-database</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>Kategorien verwalten</v-list-item-title>
                </v-list-item>
                <v-list-item to="/dictionaries/">
                  <v-list-item-icon
                    ><v-icon>mdi-notebook</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>Wörterbücher verwalten</v-list-item-title>
                </v-list-item>
                <v-list-item to="/releases/">
                  <v-list-item-icon
                    ><v-icon>mdi-upload</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>Releases verwalten</v-list-item-title>
                </v-list-item>

                <v-list-item href="https://old-admin.eis-app.de/">
                  <v-list-item-icon
                    ><v-icon>mdi-link-box-variant</v-icon></v-list-item-icon
                  >
                  <v-list-item-title
                    >Wörter-Administration von App-Version 1
                    (aktuell)</v-list-item-title
                  >
                </v-list-item>

                <!-- <v-list-item @click="rebuildWords">
                  <v-list-item-icon
                    ><v-icon>mdi-notebook</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>Wörterliste publizieren</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchUserForm from "@/components/SearchUserForm";

export default {
  name: "home",
  components: {
    SearchUserForm,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  created: function () {
    this.$store.dispatch("search/resetSearch");
  },
  methods: {
    rebuildWords() {
      this.$store.dispatch("words/rebuildWordsfile");
    },
  },
};
</script>
